import universityNameFilter from '@/filters/universityNameFilter.js';

const createUuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
  const r = Math.random() * 16 | 0;
  const v = c === 'x' ? r : (r & 0x3 | 0x8);

  return v.toString(16);
});

const calculateReferenceYear = () => {
  const now = new Date();

  const year = Number(now.getFullYear());
  const month = Number(now.getMonth() + 1);

  return (month > 6) ? (year - 1) : (year - 2);
};

const calculateHashCode = (data, referenceYear, uuid) => {
  const string = String(referenceYear) + uuid + JSON.stringify(data);

  let i;
  let l;
  let hval = 0x811c9dc5;

  for (i = 0, l = string.length; i < l; i++) {
    hval ^= string.charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }

  return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
};

const getCurrentDate = () => {
  const currentDate = new Date();

  return currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate() + '-' + currentDate.getHours() + '-' + currentDate.getMinutes() + '-' + currentDate.getSeconds();
};

const prepareSurvey = (headers = {}, preferences = {}, data = {}) => {
  return {
    headers: {
      ...{
        appVersion: '1.0.0',
        fileVersion: '1.0.0',
        type: 'company',
        createdAt: getCurrentDate(),
        referenceYear: calculateReferenceYear(),
        fileUuid: createUuid(),
      },
      ...headers,
    },
    preferences: {
      ...{
        activeStep: 1,
      },
      ...preferences,
    },
    data: { ...{}, ...data },
  };
};

const getSurveyFromFileContent = content => {
  try {
    return JSON.parse(content);
  } catch (e) {}

  try {
    const unbased64Content = atob(content);

    return JSON.parse(unbased64Content);
  } catch (e) {}

  return content || {};
};

const state = () => ({
  headers: {
    appVersion: '2.0.0',
    fileVersion: '2.0.0',
    type: 'company',
    createdAt: getCurrentDate(),
    referenceYear: calculateReferenceYear(),
    fileUuid: createUuid(),
  },
  preferences: {
    activeStep: 1,
  },
  data: {},
  result: null,
});

const getters = {
  referenceYearNumber (state) {
    return state.headers?.referenceYear || null;
  },
  referenceYear (state, getters) {
    const referenceYear = getters.referenceYearNumber;

    if (!referenceYear) {
      return 'di riferimento';
    }

    return referenceYear.toString();
  },

  safetyReferenceYear (state, getters) {
    const lastYear = state.headers?.referenceYear || null;
    if (!lastYear || !getters.totalWorkers) {
      return 'nell\'anno di riferimento';
    }

    const years = getters.totalWorkers > 1000 ? 1 : (getters.totalWorkers > 500 ? 3 : 5);
    if (years === 1) {
      return 'nell\'anno ' + lastYear;
    }

    const firstYear = lastYear - years;
    return 'negli anni ' + firstYear + '-' + lastYear;
  },

  companyType (state) {
    return state.data.company_type || null;
  },

  companyName (state, getters) {
    if (!getters.companyType) {
      return null;
    }

    if (getters.companyType === 5) {
      return universityNameFilter(state.data.company_university) || null;
    }

    return state.data.company_name || null;
  },

  isAziendaPrivata (state, getters) {
    return getters.companyType === 1;
  },

  isAziendaPartecipata (state, getters) {
    return getters.companyType === 2;
  },

  isAmministrazioneStatale (state, getters) {
    return getters.companyType === 3;
  },

  isAziendaPubblica (state, getters) {
    return getters.companyType === 4;
  },

  isUniversita (state, getters) {
    return getters.companyType === 5;
  },

  isEnteRicerca (state, getters) {
    return getters.companyType === 6;
  },

  isNotUniversita (state, getters) {
    return getters.isAziendaPrivata || getters.isAziendaPartecipata || getters.isAmministrazioneStatale || getters.isAziendaPubblica || getters.isEnteRicerca;
  },

  totalWorkers: (state, getters) => {
    let tot = 0;

    if (getters.isNotUniversita) {
      tot += parseInt(state.data.company_nb_workers_M || 0) || 0;
      tot += parseInt(state.data.company_nb_workers_F || 0) || 0;
    }

    if (getters.isUniversita) {
      tot += parseInt(state.data.company_nb_teachers_M || 0) || 0;
      tot += parseInt(state.data.company_nb_teachers_F || 0) || 0;
      tot += parseInt(state.data.company_nb_technical_M || 0) || 0;
      tot += parseInt(state.data.company_nb_technical_F || 0) || 0;
    }

    return tot;
  },

  isFirstStepCompleted: (state, getters) => {
    if (!getters.companyType) {
      return false;
    }

    if (getters.totalWorkers <= 0) {
      return false;
    }

    if (getters.isNotUniversita && !getters.companyName) {
      return false;
    }

    if (getters.isNotUniversita && !state.data.company_primary_collective_agreement) {
      return false;
    }

    if (getters.isNotUniversita && !state.data.company_establishment_year) {
      return false;
    }

    if (getters.isNotUniversita && !state.data.company_ateco2007_code) {
      return false;
    }

    if (getters.isUniversita && !state.data.company_university) {
      return false;
    }

    return true;
  },

  hasResult (state) {
    return state.result !== null;
  },
  getResult (state) {
    return state.result;
  },

  getOutputFilename (state, getters) {
    return getters.companyName + '-' + getters.referenceYearNumber + '.varige'
  },
  getFileContent (state) {
    const fileContent = prepareSurvey(state.headers, state.preferences, state.data);
    fileContent.headers.dataHash = calculateHashCode(state.data, state.headers.referenceYear, state.headers.fileUuid);

    return btoa(JSON.stringify(fileContent));
  },
};

const actions = {};

const mutations = {
  setData (state, newData) {
    state.data = { ...newData };
  },
  updateData (state, newData) {
    state.data = { ...state.data, ...newData };
  },
  setResult (state, resultData) {
    state.result = { ...resultData };
  },
  resetResult (state) {
    state.result = null;
  },
  resetSurvey (state) {
    state.headers = {
      appVersion: '2.0.0',
      fileVersion: '2.0.0',
      type: 'company',
      createdAt: getCurrentDate(),
      referenceYear: calculateReferenceYear(),
      fileUuid: createUuid(),
    };

    state.preferences = {
      activeStep: 1,
    };

    state.data = {};
    state.result = null;
  },
  loadSurveyFromFileContent: (state, content) => {
    const surveyData = getSurveyFromFileContent(content);
    const survey = prepareSurvey(surveyData.headers, surveyData.preferences, surveyData.data);

    state.headers = survey.headers;
    state.preferences = survey.preferences;
    state.data = survey.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
